export interface RequestInitWithTimeout extends RequestInit {
  // Timeout
  timeout?: number;
}

export const fetchWithTimeout = async (
  input: string | URL | Request,
  options: RequestInitWithTimeout = {}
): Promise<Response> => {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  if (input instanceof URL) {
    const response = await fetch(input, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);

    return response;
  } else if (input instanceof Request) {
    const response = await fetch(input, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);

    return response;
  } else {
    const response = await fetch(input as string, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);

    return response;
  }
};
