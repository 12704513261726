export interface AuthorizedFunction {
  (input: RequestInfo | URL, init?: RequestInit): Promise<Response>;
}

export interface AuthorizedOptions {
  token?: string;
  bearer?: string;
}

export function authorized(opts: AuthorizedOptions = {}): AuthorizedFunction {
  const { token, bearer } = opts;

  const authorization = bearer 
    ? `bearer ${bearer}` 
    : `token ${token}`;

  return (input: RequestInfo | URL, opts?: RequestInit) => {
    const init = {
      ...opts,
      headers: {
        ...opts?.headers,
        Authorization: authorization,
      },
    };
    return fetch(input, init);
  };
}