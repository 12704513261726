import { authorized } from "../authorized";

export type FetchTwoFAResult =
  | {
      success: true;
      is_verified: boolean;
    }
  | {
      success: false;
    };

interface APIError {
  field?: string;
  message?: string;
  help?: string;
}

interface APIResponse {
  errors?: APIError[];
  status: number;
}

interface FetchTwoFAResponse extends APIResponse {
  is_verified?: boolean;
}

export interface SendGridClientOptions {
  /**
   * Base URL of the sendgrid api.
   * defaults to either the environment variable REACT_APP_API_BASE_URL or https://api.sendgrid.com.
   */
  baseURL?: string;
}

/**
 * This is an adaptor used to call SendGrid based functions.
 */
export class SendGridClient {
  // Properties
  private readonly options: SendGridClientOptions;
  private readonly baseURL: string;

  private readonly defaultOptions: Partial<SendGridClientOptions> = {
    baseURL: process.env.REACT_APP_API_BASE_URL || "https://api.sendgrid.com",
  };

  // Constructor provide the base url.
  constructor(options: SendGridClientOptions) {
    this.options = {
      ...this.defaultOptions,
      ...options,
    };
    this.baseURL = getBaseURL(this.options.baseURL || "https://api.sendgrid.com");
  }

  async FetchTwoFASetting(token: string): Promise<FetchTwoFAResult> {
    const fetch = authorized({ token: token });
    try {
      const response = await fetch(`${this.baseURL}/v3/public/access_settings/multifactor`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response) {
        return { success: false };
      }
      if (response.status === 404) {
        return {
          success: true,
          is_verified: false,
        };
      }
      let body: FetchTwoFAResponse | undefined;
      body = (await response.json()) as FetchTwoFAResponse;
      if (response.status !== 200 && response.status !== 404) {
        return {
          success: false,
        };
      }
      return {
        success: true,
        is_verified: body?.is_verified ?? false,
      };
    } catch (e) {
      return { success: false };
    }
  }
}

/**
 * @ignore
 */
const getBaseURL = (baseURL: string) => {
  if (!/^https?:\/\//i.test(baseURL)) {
    return `https://${baseURL}`;
  }

  return baseURL;
};
