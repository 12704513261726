import { AppState } from "@auth0/auth0-react";
import { UnifiedProvisionPlanError } from "./constants";
import { UnifiedProvisionEligibilityResult } from "./context";
import {
  fetchUnifiedLink,
  UnifiedProvisionPlanResponse,
} from "./fetch";

export const acceptTeammateInvite = async (
  access_token: string, 
  id_token: string, 
  appState?: AppState, 
) => {
  const acceptToken = appState?.token;
  if (!acceptToken) return;

  const unifiedLinkResponse = await fetchUnifiedLink(access_token, acceptToken, id_token);
  if (!unifiedLinkResponse) return; // Success case

  const { status, errors } = unifiedLinkResponse;
  const { error_id = "ERR_TEAMMATE_INVITE_INTERNAL", message = "An unknown error occurred" } = errors?.[0] || {};

  throw new Error(`Error ${status}: ${error_id} - ${message}`);
};

export const stripUndefined = (params: any) => {
  return Object.keys(params)
    .filter((k) => typeof params[k] !== "undefined")
    .reduce((acc, key) => ({ ...acc, [key]: params[key] }), {});
};

interface RolloutConfig {
  rollout_phase: number;
  base: number;
}

export const makeSureUserCanLink = (
  unifiedAccountLinking: RolloutConfig,
  response: UnifiedProvisionPlanResponse
): UnifiedProvisionEligibilityResult => {
  if (!response.eligible) {
    if (response.reason) {
      return {
        eligible: false,
        error: response.reason as UnifiedProvisionPlanError,
      };
    }
    return {
      eligible: false,
    };
  }
  const eligibleByPreviousRolloutPhase =
    response.rollout_phase < unifiedAccountLinking.rollout_phase;

  const eligibleByCurrentRolloutPhase =
    response.rollout_phase === unifiedAccountLinking.rollout_phase &&
    response.base <= unifiedAccountLinking.base;

  if (!eligibleByPreviousRolloutPhase && !eligibleByCurrentRolloutPhase) {
    return {
      eligible: false,
    };
  }
  return {
    eligible: true,
    email: response.email,
  };
};
