import React, { useEffect, useState } from "react";

declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}

// Initialize dataLayer if it's not already set up
window.dataLayer = window.dataLayer || [];

interface AnalyticsComponentProps {
  GID?: string;
}

const DefaultGID = "G-8W5LR442LD";

const AnalyticsComponent: React.FC<AnalyticsComponentProps> = ({
  GID = DefaultGID,
}: AnalyticsComponentProps) => {
  // State to store Client ID and Session ID
  const [clientID, setClientID] = useState<string>("");
  const [sessionID, setSessionID] = useState<string>("");

  useEffect(() => {
    let isMounted = true;

    const fetchAnalyticsID = async (GID: string) => {
      if (typeof window.gtag === "function") {
        // Retrieve the Client ID from Google Analytics
        const clientID = await fetchClientID(GID);
        if (isMounted) {
          setClientID(clientID);
        }
        const sessionID = await fetchSessionID(GID);
        if (isMounted) {
          setSessionID(sessionID);
        }
      } else {
        console.error("Google Analytics is not loaded");
      }
    };

    fetchAnalyticsID(GID).then();
    return () => {
      isMounted = false; // Cleanup: mark component as unmounted when it is unmounted
    };
  }, [clientID, setClientID, sessionID, setSessionID, GID]); // Run only once on component mount

  return (
    <div>
      <h3>Analytics Information</h3>
      <p>
        <strong>Client ID:</strong> {clientID || "Not available"}
      </p>
      <p>
        <strong>Session ID:</strong> {sessionID || "Not available"}
      </p>
    </div>
  );
};

export function fetchClientID(GID: string = DefaultGID): Promise<string> {
  return new Promise((resolve) => {
    if (typeof window.gtag !== "function") {
      resolve("");
    }
    // Retrieve the Client ID from Google Analytics
    window.gtag("get", GID, "client_id", (clientID: string) => {
      resolve(clientID);
    });
  });
}

export function fetchSessionID(GID: string = DefaultGID): Promise<string> {
  return new Promise((resolve) => {
    if (typeof window.gtag !== "function") {
      resolve("");
    }
    // Retrieve the Session ID from Google Analytics
    window.gtag("get", GID, "session_id", (sessionID: string) => {
      resolve(sessionID);
    });
  });
}

export default AnalyticsComponent;
